<template>
  <container-list-extend title="Formation">
    <template v-slot:title>
      <div class="w-100 p-1 pl-h-2" v-if="!vehicule.new">
        <div>
          <strong>{{ vehicule.immatriculation }}</strong>
        </div>
        <div>{{ vehicule.modele }}</div>
      </div>
      <div v-else class="w-100 text-primary pl-2">
        <div>Saisir les informations</div>
      </div>
    </template>
    <template v-slot:form>
      <div class="p-3">
        <div class="mb-3">
          <menuItem title="Véhicule" @click="tab = 'vehicule'" :active="tab === 'vehicule'" icon="truck"></menuItem>
          <menuItem title="Contrat" @click="tab = 'contrat'" :active="tab === 'contrat'" icon="file-text"></menuItem>
          <menuItem title="Affectations" @click="tab = 'affect'" :active="tab === 'affect'" icon="users"></menuItem>
        </div>
        <base-form
          @click="updateVehicule(vehicule)"
          @delete="deleteVehicule(vehicule)"
          :statut="statut"
          :loading="loading"
          deleteBtn
        >
          <div v-show="tab === 'vehicule'" class="mb-3">
            <base-form-row row>
              <base-input
                v-model="vehicule.immatriculation"
                inputText="Immatriculation"
                :errors="feedback.immatriculation"
              ></base-input>
              <base-input v-model="vehicule.modele" inputText="Modèle" :errors="feedback.modele"></base-input>
            </base-form-row>
            <base-form-row row>
              <base-input
                v-model="vehicule.puissance_fiscale"
                inputText="Puissance fiscale P6"
                :errors="feedback.puissance_fiscale"
              ></base-input>
              <base-input
                v-model="vehicule.puissance_dyn"
                inputText="Puissance DYN P2"
                :errors="feedback.puissance_dyn"
              ></base-input>
            </base-form-row>
            <base-form-row row>
              <base-input
                v-model="vehicule.valeur_vehicule"
                inputText="Valeur du véhicule"
                :errors="feedback.valeur_vehicule"
              ></base-input>
              <base-input
                v-model="vehicule.valeur_carbone"
                inputText="Prix carbone"
                :errors="feedback.valeur_carbone"
              ></base-input>
            </base-form-row>
            <base-form-row row>
              <base-inputDatePicker
                v-model="vehicule.date_immatriculation"
                inputText="Date de première immatriculation"
                :errors="feedback.date_immatriculation"
              ></base-inputDatePicker>
            </base-form-row>
            <div class="pt-4">
              <uploader
                :id="'doc_vehicule_' + vehicule.id"
                url="/upload/document"
                file_system="VEHICULES"
                file_field="vehicule"
                :file_key="vehicule.id"
                title="Documents véhicule"
              ></uploader>
            </div>
          </div>
          <div v-show="tab === 'contrat'" class="mb-3">
            <base-form-row row>
              <base-select
                inputText="Catégorie"
                v-model.number="vehicule.categorie_id"
                :options="data.categories"
                :errors="feedback.categorie_id"
              ></base-select>
              <base-input
                v-model="vehicule.prestataire"
                inputText="Prestataire"
                :errors="feedback.prestataire"
              ></base-input>
            </base-form-row>
            <base-form-row row>
              <base-select
                inputText="Société contractante"
                v-model.number="vehicule.societe_id"
                :options="data.societes"
                :errors="feedback.societe_id"
              ></base-select>
            </base-form-row>
            <base-form-row row>
              <base-input
                v-model="vehicule.num_contrat"
                inputText="Numéro de contrat"
                :errors="feedback.num_contrat"
              ></base-input>
              <base-input v-model="vehicule.nb_km" inputText="Kilomètres prévus" :errors="feedback.nb_km"></base-input>
            </base-form-row>
            <base-form-row row>
              <base-inputDatePicker
                v-model="vehicule.debut_contrat"
                inputText="Date de début de contrat"
                :errors="feedback.debut_contrat"
              ></base-inputDatePicker>
              <base-inputDatePicker
                v-model="vehicule.fin_contrat"
                inputText="Date de fin de contrat"
                :errors="feedback.fin_contrat"
              ></base-inputDatePicker>
            </base-form-row>
            <base-form-row row>
              <base-inputDatePicker
                v-model="vehicule.date_restitution"
                inputText="Date de restitution"
                :errors="feedback.date_restitution"
              ></base-inputDatePicker>
              <base-input v-model="vehicule.classeur" inputText="Classeur" :errors="feedback.classeur"></base-input>
            </base-form-row>
            <base-form-row row>
              <base-input v-model="vehicule.loyer_ht" inputText="Loyer HT" :errors="feedback.loyer_ht"></base-input>
              <base-input v-model="vehicule.loyer_ttc" inputText="Loyer TTC" :errors="feedback.loyer_ttc"></base-input>
            </base-form-row>
            <base-form-row row>
              <base-select
                inputText="Assurance"
                v-model.number="vehicule.assurance_id"
                :options="data.assurances"
                :errors="feedback.assurance_id"
              ></base-select>
              <base-input
                v-model="vehicule.tarif_assurance"
                inputText="Tarif assurance"
                :errors="feedback.tarif_assurance"
              ></base-input>
            </base-form-row>
            <div class="pt-4">
              <uploader
                :id="'doc_vehicule_contrat_' + vehicule.id"
                url="/upload/document"
                file_system="VEHICULES"
                file_field="contrat"
                :file_key="vehicule.id"
                title="Documents véhicule"
              ></uploader>
            </div>
          </div>
          <div v-show="tab === 'affect'" class="mb-3">
            <BaseToolBar title="Affectations" subtitle="Liste des affectations pour ce véhicule">
              <BaseButton
                class="btn btn-primary ml-3"
                v-tooltip="'Ajouter une affectation'"
                @click="affectCollaborateur"
                :loading="loading"
                icon="plus"
                text="Ajouter"
              ></BaseButton>
            </BaseToolBar>
            <div v-for="affectation in vehicule.collaborateurs" :key="affectation.id" class="d-flex mb-2 rounded">
              <div class="w-100 mr-3">
                <select-collaborateur
                  v-model.number="affectation.collaborateur_id"
                  :text="'Affecter à un collaborateur'"
                ></select-collaborateur>
              </div>
              <div class="w-100 mr-3">
                <base-inputDatePicker
                  v-model="affectation.date_affectation"
                  inputText="Affectation"
                  :errors="feedback.date_affectation"
                ></base-inputDatePicker>
              </div>
              <div class="w-100 mr-3">
                <base-inputDatePicker
                  v-model="affectation.date_restitution"
                  inputText="Restitution"
                  :errors="feedback.date_restitution"
                ></base-inputDatePicker>
              </div>
              <div class="w-100 mr-3">
                <base-input
                  v-model="affectation.kms_parcourus"
                  inputText="Kms parcourus"
                  :errors="feedback.num_contrat"
                ></base-input>
              </div>
              <div class="w-100 mr-3">
                <base-select
                  inputText="Catégorie"
                  v-model.number="affectation.categorie_id"
                  :options="data.categoriesAffectation"
                  :errors="feedback.categorie_id"
                ></base-select>
              </div>
              <div class="mr-3 pt-3">
                <Checkbox
                  v-model="affectation.doc_signe"
                  text="Bordereau"
                  :errors="feedback.categorie_id"
                  :iconActive="true"
                ></Checkbox>
              </div>
              <div class="flex-none pt-2">
                <ButtonCircle
                  name="trash"
                  @click="deleteAffectation(affectation)"
                  class="m-1"
                  :loading="loading"
                ></ButtonCircle>
              </div>
            </div>

            <BaseToolBar
              title="Affectations à un établissement"
              subtitle="Liste des affectations par établissement"
              class="mt-5"
            >
              <BaseButton
                class="btn btn-primary ml-3"
                v-tooltip="'Ajouter une affectation (étab)'"
                @click="affectEtablissement"
                :loading="loading"
                icon="plus"
                text="Ajouter"
              ></BaseButton>
            </BaseToolBar>
            <div
              v-for="affectationEtab in vehicule.etablissements"
              :key="affectationEtab.id"
              class="d-flex mb-2 rounded"
            >
              <div class="w-100 mr-3">
                <base-select
                  inputText="Etablissement"
                  v-model.number="affectationEtab.etablissement_id"
                  :options="data.etablissements"
                  :errors="feedback.etablissement_id"
                ></base-select>
              </div>
              <div class="w-100 mr-3">
                <base-inputDatePicker
                  v-model="affectationEtab.date_debut"
                  inputText="Affectation"
                  :errors="feedback.date_debut"
                ></base-inputDatePicker>
              </div>
              <div class="w-100 mr-3">
                <base-inputDatePicker
                  v-model="affectationEtab.date_fin"
                  inputText="Restitution"
                  :errors="feedback.date_fin"
                ></base-inputDatePicker>
              </div>
              <div class="flex-none pt-2">
                <ButtonCircle
                  name="trash"
                  @click="deleteAffectationEtablissement(affectationEtab)"
                  class="m-1"
                  :loading="loading"
                ></ButtonCircle>
              </div>
            </div>
            <div class="pt-4">
              <uploader
                :id="'doc_vehicule_affectation_' + vehicule.id"
                url="/upload/document"
                file_system="VEHICULES"
                file_field="affectation"
                :file_key="vehicule.id"
                title="Documents liés aux affectations"
              ></uploader>
            </div>
          </div>
        </base-form>
      </div>
    </template>
  </container-list-extend>
</template>
<script>
// import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

// import DescriptionFromList from "@/components/bases/DescriptionFromList";
// import BaseIcon from "@/components/bases/Icon.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
// import BaseInputNumber from "@/components/bases/InputNumber.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
// import BaseInputArea from "@/components/bases/InputArea.vue";
// import Uploader from "@/components/bases/Uploader.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";
import menuItem from "@/components/qsser/menuItem.vue";
import BaseButton from "@/components/bases/Button.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  name: "FormationsItem",
  components: {
    // BaseIcon,
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInput,
    // BaseInputNumber,
    BaseSelect,
    BaseInputDatePicker,
    SelectCollaborateur,
    menuItem,
    BaseToolBar,
    BaseButton,
    ButtonCircle,
    Uploader,
    // DescriptionFromList,
    // BaseInputArea,
    Checkbox,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
      tab: "vehicule",
    };
  },
  props: ["vehicule", "data"],
  methods: {
    updateVehicule: function (vehicule) {
      this.feedback = {};
      this.loading = true;
      this.$http
        .put("/vehicules/" + vehicule.id, vehicule)
        .then((resp) => this.$emit("update", resp.data))
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteVehicule: function (vehicule) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this.$http
        .delete("/vehicules/" + vehicule.id)
        .then(() => this.$emit("destroy", vehicule.id))
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    affectCollaborateur: function () {
      this.vehicule.collaborateurs.push({
        collaborateur_id: null,
        date_affectation: null,
        date_restitution: null,
        kms_parcourus: 0,
        categorie_id: null,
        doc_signe: null,
      });
    },

    affectEtablissement: function () {
      this.vehicule.etablissements.push({
        etablissement_id: null,
        date_debut: null,
        date_fin: null,
      });
    },

    deleteAffectation: function (affectation) {
      this.$http
        .delete("/vehicules-affectation/" + affectation.id)
        .then(() => {
          const index = this.vehicule.collaborateurs.findIndex((x) => x.id === affectation.id);
          this.vehicule.collaborateurs.splice(index, 1);
        })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },

    deleteAffectationEtablissement: function (affectation) {
      this.$http
        .delete("/vehicules-affectation-etab/" + affectation.id)
        .then(() => {
          const index = this.vehicule.etablissements.findIndex((x) => x.id === affectation.id);
          this.vehicule.etablissements.splice(index, 1);
        })
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="css"></style>
