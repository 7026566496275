<template>
  <div
    class="col-2 mr-2 text-center cursor-pointer p-1 pt-3 pb-3"
    style="max-width:6rem !important; min-width:6rem !important; line-height:1rem;"
    :class="{'btn btn-primary': active, 'btn btn-outline-primary': !active}"
    @click="$emit('click')"
  >
    <div>
      <BaseIcon :name="icon"></BaseIcon>
    </div>
    <div class="mt-1">
      <small>{{ title }}</small>
    </div>
  </div>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  name: "ContratFormTab",
  components: {
    BaseIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>